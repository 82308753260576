export default {
  data: () => ({
    smsfollowupTemp: {
      id: 0,
      name: "",
      for_old_client: false,
    },
    loading: false,
    backUrl: "/sms-followup-template",
    isBodyLimitExceeded: false,
  }),
  watch: {
    "smsfollowupTemp.body": function (newBody) {
      // Watch for changes in 'body' and limit the input length
      if (newBody.length > 160) {
        this.smsfollowupTemp.body = newBody.substring(0, 160);
        this.isBodyLimitExceeded = true;
      } else {
        this.isBodyLimitExceeded = false;
      }
    },
  },
  methods: {
    handleInput() {
      // Reset the error when the user inputs characters
      this.isBodyLimitExceeded = false;
    },
    resetForm() {
      this.$refs.observer.reset();
      this.smsfollowupTemp = {
        id: 0,
        name: "",
      };
    },
    validatesmsfollowupTemp() {
      this.smsfollowupTemp.id > 0 ? this.update() : this.add();
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 10000);
    },
    update() {
      // This function updates the bank account details
      let _vm = this;
      this.axios
        .post("/sms-followup-templates/" + this.smsfollowupTemp.id, {
          ...this.smsfollowupTemp,
          _method: "PATCH",
        })
        .then(function (response) {
          _vm.smsfollowupTemp = response.data.data;
          _vm.$router.push(_vm.backUrl);
        })
        .catch(function () {});
    },

    add() {
      let _vm = this;
      this.axios
        .post("/sms-followup-templates", _vm.smsfollowupTemp)
        .then(function () {
          _vm.$router.push(_vm.backUrl);
        })
        .catch(function () {});
    },
    getDetail() {
      let _vm = this;
      this.axios
        .get("/sms-followup-templates/" + this.$route.params.id)
        .then(function (response) {
          _vm.smsfollowupTemp = response.data.data;
        })
        .catch(function () {});
    },
  },
  mounted() {
    if (this.$route.params.id > 0) {
      this.getDetail();
    }
  },
};
